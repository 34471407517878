var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/button/Button.macro.njk"] = require( "@naturehouse/design-system/components/atoms/button/Button.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/dialog/partials/old-footer.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/atoms/button/Button.macro.njk", false, "design-system/v3/components/molecules/dialog/partials/old-footer.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("buttonTemplate", t_1);
output += "\n\n<footer class=\"nh-dialog__footer nh-dialog__footer--old\">\n    ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "otherButton")) === true) {
output += "\n        ";
output += runtime.suppressValue((lineno = 4, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"render"), "buttonTemplate[\"render\"]", context, [runtime.contextOrFrameLookup(context, frame, "otherButton")])), env.opts.autoescape);
output += "\n    ";
;
}
output += "\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "cancelButtonText"),"")) {
output += "\n        <button\n            class=\"nh-button nh-button--secondary\"\n            is=\"nh-button\"\n            variant=\"secondary\"\n            data-role=\"close\"\n        >\n            ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cancelButtonText"), env.opts.autoescape);
output += "\n        </button>\n    ";
;
}
output += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "ctaButton")) {
output += "\n        <button\n            class=\"nh-button nh-button--";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"variant"), env.opts.autoescape);
output += "\"\n            is=\"nh-button\"\n            variant=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"variant"), env.opts.autoescape);
output += "\"\n            data-role=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"role"),"primary"), env.opts.autoescape);
output += "\"\n            ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"type")) === true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"type")) {
output += "type=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"type"), env.opts.autoescape);
output += "\"";
;
}
output += "\n            ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"form")) === true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"form")) {
output += "form=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"form"), env.opts.autoescape);
output += "\"";
;
}
output += "\n            ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"disabled")) === true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"disabled")) {
output += "disabled=\"disabled\"";
;
}
output += "\n            ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"loading")) === true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"loading")) {
output += "loading";
;
}
output += "\n        >";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ctaButton")),"label"), env.opts.autoescape);
output += "</button>\n    ";
;
}
output += "\n</footer>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/dialog/partials/old-footer.html.njk"] , dependencies)