var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/button/Button.macro.njk"] = require( "@naturehouse/design-system/components/atoms/button/Button.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/dialog/partials/confirmation-footer.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/atoms/button/Button.macro.njk", false, "design-system/v3/components/molecules/dialog/partials/confirmation-footer.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("buttonTemplate", t_1);
output += "\n\n";
var t_4;
t_4 = {"variant": "secondary"};
frame.set("secondaryButtonVariant", t_4, true);
if(frame.topLevel) {
context.setVariable("secondaryButtonVariant", t_4);
}
if(frame.topLevel) {
context.addExport("secondaryButtonVariant", t_4);
}
output += "\n\n";
var t_5;
t_5 = {"variant": "primary-danger"};
frame.set("primaryButtonVariant", t_5, true);
if(frame.topLevel) {
context.setVariable("primaryButtonVariant", t_5);
}
if(frame.topLevel) {
context.addExport("primaryButtonVariant", t_5);
}
output += "\n\n<footer class=\"nh-dialog__footer--new\" data-footer-variant=\"confirmation\">\n    ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"secondaryButton")) === true) {
output += "\n        ";
output += runtime.suppressValue((lineno = 12, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"render"), "buttonTemplate[\"render\"]", context, [env.getFilter("merge").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"secondaryButton"),runtime.contextOrFrameLookup(context, frame, "secondaryButtonVariant"))])), env.opts.autoescape);
output += "\n    ";
;
}
output += "\n\n    ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"primaryButton")) === true) {
output += "\n        ";
output += runtime.suppressValue((lineno = 16, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"render"), "buttonTemplate[\"render\"]", context, [env.getFilter("merge").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"primaryButton"),runtime.contextOrFrameLookup(context, frame, "primaryButtonVariant"))])), env.opts.autoescape);
output += "\n    ";
;
}
output += "\n</footer>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/dialog/partials/confirmation-footer.html.njk"] , dependencies)